<template>
    <div class="banner">
<!--        <div style="margin-top: 10px">-->
<!--            <a href="http://꿀벳평생주소.com" target="_blank">-->
<!--                <img alt="" src="../assets/images/banner/ptn/www.jpg" style="width: 100%">-->
<!--            </a>-->
<!--        </div>-->

        <div style="margin-top:10px">
            <a href="https://t.me/yewtoto" target="_blank">
                <img alt="" src="../assets/images/banner/tel.gif" style="width: 100%">
            </a>
        </div>






    </div>
</template>

<script>
    export default {
        name: "RightBarBannerComp"
    }
</script>

<style scoped>

    .banner{
        width: 100%;
        padding: 1px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
</style>