<template>
  <div>
    <!--읽지 않은 메세지-->
    <message-comp></message-comp>

    <div class="footer">

      <div class="footer_thirdparty" style="">
        <div class="th_item">
          <img src="../assets/images/logo/thirdpartylogo/1x2game.png">
          <img src="../assets/images/logo/thirdpartylogo/7mojos.png">
          <img src="../assets/images/logo/thirdpartylogo/abslute.png">
          <img src="../assets/images/logo/thirdpartylogo/amigo.png">
          <img src="../assets/images/logo/thirdpartylogo/amatic.png">
          <img src="../assets/images/logo/thirdpartylogo/asiagame.png">
          <img src="../assets/images/logo/thirdpartylogo/astar.png">
          <img src="../assets/images/logo/thirdpartylogo/smartsoft.png">
          <img src="../assets/images/logo/thirdpartylogo/betgamestv.png">
          <img src="../assets/images/logo/thirdpartylogo/bgaming.png">
          <img src="../assets/images/logo/thirdpartylogo/bigtimegame.png">
          <img src="../assets/images/logo/thirdpartylogo/blueprint.png">
          <img src="../assets/images/logo/thirdpartylogo/boongo.png">
          <img src="../assets/images/logo/thirdpartylogo/caleta.png">
          <img src="../assets/images/logo/thirdpartylogo/dowin.png">
          <img src="../assets/images/logo/thirdpartylogo/evoplay.png">
          <img src="../assets/images/logo/thirdpartylogo/ezugi.png">
          <img src="../assets/images/logo/thirdpartylogo/fcfachai.png">
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import MessageComp from "./MessageComp";

export default {
  name: "FootComp",
  components: {MessageComp},
  created() {
  }
}
</script>

<style scoped>
/*.footer {*/
/*    width: 100%;*/
/*    padding: 20px 320px 16px 320px;*/
/*    color: #fff9ff;*/
/*    background: var(--footBg);*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

/*.footer img{*/
/*    width: 100%;*/
/*}*/

@media screen and (max-width: 1024px) {

}

.footer {
  width: 100%;
  padding: 0px 320px 16px 320px;
  color: #fff9ff;
  border-top: 1px solid #945d02;
  --background: var(--footBg);
}


.footer .footer_cont img {
  max-height: 100px;
}

.footer .footer_cont .footer_bottom .bitem p {
  padding: 3px 0;
  color: #a3a1a7;
}


.footer .footer_thirdparty {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: wrap;
}

.footer .footer_thirdparty .th_item {
  width: 8%;
  text-align: center;
}

.footer .footer_thirdparty .th_item img {
  width: 90%;
}


</style>