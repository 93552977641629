import http from "./axios/http";

/**
 * 베팅가능 경기리스트
 * @param disp displayPostion
 * @returns {AxiosPromise}
 */
export function getSportsGames(disp) {
  return http.post('/sports/games?t=' + new Date().getTime(), {'displayPosition': disp})
}

export function getSportsGamesByGroupCode(groupCode) {
  return http.post('/sports/games?t=' + new Date().getTime(), {'groupCode': groupCode})
}

export function getSportsMoreGames(groupCode) {
  return http.post('/sports/more_games?t=' + new Date().getTime(), {'groupCode': groupCode})
}


export function getRecentlyGames() {
  return http.get('/sports/recently_games?t=' + new Date().getTime())
}
export function getRecentlyEndGames() {
  return http.get('/sports/recently_end_games?t=' + new Date().getTime())
}

/**
 * 진행중 경기 리그 리스트
 * @param search
 * @returns {AxiosPromise}
 */
export function getLeagues(search) {
  return http.post('/sports/get_leagues?t=' + new Date().getTime(), search)
}

/**
 * 마감된 경기리스트
 * @param search
 * @param pageNum
 * @param pageSize
 * @param orderBy
 * @returns {AxiosPromise}
 */
export function getSportsGameEnd(search, pageNum, pageSize, orderBy) {
  return http.post('/sports/sports_games_end?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&t=' + new Date().getTime(), search)
}

//get_game_count_bygrgt
export function getSportsGameCountGroupByType(isinplay,displayPostion) {
  return http.get('/sports/get_game_count_bygrgt?isinplay='+isinplay+'&displayPostion='+displayPostion + '&t=' + new Date().getTime())
}